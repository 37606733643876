import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { EditOutlined, ArrowBackIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  useGetUserDetails,
  useUpdateUser,
  useUpdateUserImage,
  useGetOrganizationDetails,
} from "../apiCalls/userApiCalls";
import Loader from "../components/Loader";

export default function EditProfile() {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    organization: null,
  });
  const [organizationDetails, setOrganizationDetails] = useState({
    salesforceInstanceUrl: "",
    salesforceUserSecurityToken: "",
    salesforceUsername: "",
    salesforceUserPassword: "",
    salesforceSyncEnabled: false,
  });

  const nameInputElement = useRef();
  const emailInputElement = useRef();
  const [isSalesforceSyncEnabled, setIsSalesforceSyncEnabled] = useState(false);

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const userId = currentUser.user._id;
  const token = currentUser.token;

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );
  const {
    mutate: updateUserMutate,
    isLoading: isUpdateUserLoading,
    isError: isUpdateUserError,
    error: updateUserError,
  } = useUpdateUser(userId);
  const {
    mutate: updateUserImageMutate,
    isLoading: isUpdateUserImageLoading,
    isError: isUpdateUserImageError,
    error: updateUserImageError,
  } = useUpdateUserImage();
  const {
    isLoading: isOrganizationLoading,
    data: organizationData,
    refetch: refetchOrganization,
  } = useGetOrganizationDetails(
    userDetails?.data.data.organization,
    token,
    {
      enabled: false, // Disable automatic query execution
    }
  );

  useEffect(() => {
    if (userDetails) {
      setUserData({
        name: userDetails.data.data.name,
        email: userDetails.data.data.email,
        organization: userDetails.data.data.organization,
      });
      if (userDetails.data.data.organization) {
        refetchOrganization(); // Manually refetch organization details
      }
    }
  }, [userDetails, refetchOrganization]);

  useEffect(() => {
    if (organizationData) {
      setOrganizationDetails({
        name: organizationData.data.data.name,
        salesforceInstanceUrl: organizationData.data.data.salesforceInstanceUrl,
        salesforceUserSecurityToken:
          organizationData.data.data.salesforceUserSecurityToken,
        salesforceUsername: organizationData.data.data.salesforceUsername,
        salesforceUserPassword: organizationData.data.data.salesforceUserPassword,
        salesforceSyncEnabled: organizationData.data.data.salesforceSyncEnabled,
      });
      setIsSalesforceSyncEnabled(
        organizationData.data.data.salesforceSyncEnabled
      );
    }
  }, [organizationData]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOrganizationChange = (e) => {
    const { name, value } = e.target;
    setOrganizationDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSalesforceSyncChange = (e) => {
    setIsSalesforceSyncEnabled(e.target.checked);
    setOrganizationDetails((prevData) => ({
      ...prevData,
      salesforceSyncEnabled: e.target.checked,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      userId: userId,
      token: token,
      name: userData.name,
      email: userData.email,
    };

    updateUserMutate(data);
  };

  const handleOrganizationSubmit = (event) => {
    event.preventDefault();
    const data = {
      ...organizationDetails,
      salesforceSyncEnabled: isSalesforceSyncEnabled,
    };

    // Call update organization API here
  };

  const handleFileChange = (e) => {
    const imageData = {
      token: token,
      userId: userId,
      image: e.target.files[0],
    };
    updateUserImageMutate(imageData);
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      {isUserLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="pt-10 flex flex-col items-center">
                <form action="" className="flex items-end">
                  <div
                    className="w-28 h-28 border border-gray-200  rounded-full"
                    style={{
                      backgroundImage: `url("${userDetails.data.data.imgUrl}"), url("${fallbackImage}")`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div className="w-7 h-7 -ml-8  rounded-full bg-blue-400 text-white flex justify-center">
                    <label htmlFor="file">
                      <EditOutlined style={{ fontSize: "16" }} />
                    </label>
                  </div>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </form>
              </div>

              <form className="pt-10 space-y-3 pb-8" onSubmit={handleSubmit}>
                <div className="space-y-2">
                  <label className="text-base text-gray-500" htmlFor="">
                    Name
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      name="name"
                      value={userData.name}
                      onChange={handleUserChange}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-base text-gray-500" htmlFor="">
                    Email Address
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="email"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      name="email"
                      value={userData.email}
                      onChange={handleUserChange}
                    />
                  </div>
                </div>

                <div className="pt-8">
                  <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                    {isUpdateUserLoading ? "...Is Saving" : "Save"}
                  </button>
                </div>
                {isUpdateUserError && (
                  <div className="text-sm font-medium text-red-600 pt-2">
                    <p>{updateUserError.response.data.error}</p>
                  </div>
                )}
                <Link to="/changePassword">
                  <h2 className="font-medium text-end pt-3">Change Password</h2>
                </Link>
              </form>

              {userData.organization && (
                <form
                  className="pt-10 space-y-3 pb-8"
                  onSubmit={handleOrganizationSubmit}
                >
                  <div className="space-y-2">
                    <h3 className="text-lg font-medium text-gray-500">
                      Organization Details
                    </h3>
                    {/* display organization name as a heading */}
                    <h2 className="text-xl font-medium text-black">
                      Organization: {organizationDetails.name}
                    </h2>
                    <div className="space-y-2">
                      <label className="text-base md:text-lg text-gray-500" htmlFor="">
                        Salesforce Sync Enabled
                      </label>
                      <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                        <input
                          type="checkbox"
                          className="outline-none text-base font-medium text-black"
                          name="salesforceSyncEnabled"
                          checked={isSalesforceSyncEnabled}
                          onChange={handleSalesforceSyncChange}
                        />
                      </div>
                    </div>

                    {isSalesforceSyncEnabled && (
                      <>
                        <div className="space-y-2">
                          <label className="text-base md:text-lg text-gray-500" htmlFor="">
                            Salesforce Instance URL
                          </label>
                          <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                            <input
                              type="text"
                              className="outline-none w-full text-base font-medium text-black"
                              name="salesforceInstanceUrl"
                              value={organizationDetails.salesforceInstanceUrl}
                              onChange={handleOrganizationChange}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <label className="text-base md:text-lg text-gray-500" htmlFor="">
                            Salesforce API User's Username
                          </label>
                          <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                            <input
                              type="text"
                              className="outline-none w-full text-base font-medium text-black"
                              name="salesforceUsername"
                              value={organizationDetails.salesforceUsername}
                              onChange={handleOrganizationChange}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <label className="text-base md:text-lg text-gray-500" htmlFor="">
                            Salesforce API User's Password
                          </label>
                          <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                            <input
                              type="password"
                              className="outline-none w-full text-base font-medium text-black"
                              name="salesforceUserPassword"
                              value={organizationDetails.salesforceUserPassword}
                              onChange={handleOrganizationChange}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <label className="text-base md:text-lg text-gray-500" htmlFor="">
                            Salesforce API User's Security Token
                          </label>
                          <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                            <input
                              type="text"
                              className="outline-none w-full text-base font-medium text-black"
                              name="salesforceUserSecurityToken"
                              value={organizationDetails.salesforceUserSecurityToken}
                              onChange={handleOrganizationChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="pt-8">
                    <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                      {isUpdateUserLoading ? "...Is Saving" : "Save"}
                    </button>
                  </div>
                  {isUpdateUserError && (
                    <div className="text-sm font-medium text-red-600 pt-2">
                      <p>{updateUserError.response.data.error}</p>
                    </div>
                  )}
                </form>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
