import React, { useRef, useState, useEffect } from "react";
import { EditOutlined, ArrowBackIos } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import {
  useGetUserDetails,
  useUpdateUser,
  useUpdateUserImage,
  useGetOrganizationDetails,
} from "../apiCalls/userApiCalls";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

export default function EditUser() {
  const nameInputElement = useRef();
  const codeInputElement = useRef();
  const emailInputElement = useRef();
  const roleInputElement = useRef();
  const hourlyRateInputElement = useRef();
  const overtimeRateInputElement = useRef();
  const organizationInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { userId } = useParams();

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(userId, token);
  const organizationId = userDetails?.data.data.organization;
  const { isLoading: isOrganizationLoading, data: organizationDetails } = useGetOrganizationDetails(organizationId, token);

  const {
    mutate: updateUserMutate,
    isLoading: isUpdateUserLoading,
    isError: isUpdateUserError,
    error: updateUserError,
  } = useUpdateUser(userId);
  const {
    mutate: updateUserImageMutate,
    isLoading: isUpdateUserImageLoading,
    isError: isUpdateUserImageError,
    error: updateUserImageError,
  } = useUpdateUserImage();

  const handleFileChange = (e) => {
    const imageData = {
      token: token,
      userId: userId,
      image: e.target.files[0],
    };
    updateUserImageMutate(imageData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      userId: userId,
      token: token,
      name: nameInputElement.current?.value,
      employeeCode: codeInputElement.current?.value,
      email: emailInputElement.current?.value,
      role: roleInputElement.current?.value,
      hourlyRate: hourlyRateInputElement.current?.value,
      overtimeRate: overtimeRateInputElement.current?.value,
      organization: organizationId,
    };

    updateUserMutate(data);
  };

  const allRoles = ["foreman", "worker", "sales"];
  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      {isUserLoading || isOrganizationLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="pt-10 flex flex-col mb-5 items-center">
                <form className="flex items-end">
                  <div
                    className="w-28 h-28 border border-gray-200  rounded-full"
                    style={{
                      backgroundImage: `url("${userDetails.data.data.imgUrl}"), url("${fallbackImage}")`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div className="w-7 h-7 -ml-8 rounded-full bg-blue-400 text-white flex justify-center">
                    <label htmlFor="file">
                      <EditOutlined style={{ fontSize: "16" }} />
                    </label>
                  </div>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </form>
              </div>

              <form onSubmit={handleSubmit} className="pb-8">
                <div className="space-y-3">
                  <div className="space-y-2">
                    <label className="text-base text-gray-500" htmlFor="">
                      Name
                    </label>
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <input
                        type="text"
                        className="outline-none w-full text-base font-medium text-black placeholder-black"
                        name="name"
                        defaultValue={userDetails.data.data.name}
                        ref={nameInputElement}
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label className="text-base text-gray-500" htmlFor="">
                      Employee ID
                    </label>
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <input
                        type="text"
                        className="outline-none w-full text-base font-medium text-black placeholder-black"
                        name="employeeCode"
                        defaultValue={userDetails.data.data.employeeCode}
                        ref={codeInputElement}
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label className="text-base text-gray-500" htmlFor="">
                      Email Address
                    </label>
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <input
                        type="email"
                        className="outline-none w-full text-base font-medium text-black placeholder-black"
                        name="email"
                        defaultValue={userDetails.data.data.email}
                        ref={emailInputElement}
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label className="text-base text-gray-500" htmlFor="">
                      Type
                    </label>
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <select
                        name="role"
                        className="w-full outline-none capitalize"
                        ref={roleInputElement}
                      >
                        <option value={userDetails.data.data.role}>
                          {userDetails.data.data.role}
                        </option>
                        {allRoles.map((role, index) => (
                          <>
                            {userDetails.data.data.role !== role && (
                              <option key={index} value={role}>
                                {role}
                              </option>
                            )}
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  {userDetails.data.data.role !== "admin" && (
                    <>
                      <div className="space-y-2">
                        <label className="text-base text-gray-500" htmlFor="">
                          Hourly Rate
                        </label>
                        <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                          <input
                            type="number"
                            className="outline-none w-full text-base font-medium text-black placeholder-black"
                            name="hourlyRate"
                            defaultValue={userDetails.data.data.hourlyRate}
                            ref={hourlyRateInputElement}
                            step=".01"
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </div>
                      <div className="space-y-2">
                        <label className="text-base text-gray-500" htmlFor="">
                          Overtime Rate
                        </label>
                        <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                          <input
                            type="number"
                            className="outline-none w-full text-base font-medium text-black placeholder-black"
                            name="overtimeRate"
                            defaultValue={userDetails.data.data.overtimeRate}
                            ref={overtimeRateInputElement}
                            step=".01"
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="space-y-2">
                    <label className="text-base text-gray-500" htmlFor="">
                      Organization
                    </label>
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <input
                        type="text"
                        className="outline-none w-full text-base font-medium text-black"
                        name="organization"
                        defaultValue={organizationDetails?.data?.data?.name || "No Organization"}
                        readOnly
                        ref={organizationInputElement}
                      />
                    </div>
                  </div>
                  <div className="pt-4">
                    <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                      {isUpdateUserLoading ? "Saving..." : "Save"}
                    </button>
                  </div>
                  {isUpdateUserError && (
                    <div className="text-sm font-medium text-red-600 pt-2">
                      <p>{updateUserError.response.data.error}</p>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
