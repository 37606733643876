import React, { useState, useEffect } from "react";
import { useDeleteJobType, useGetJobTypes, useGetOrganizationDetails, useSyncJobTypesFromSalesforce } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import { Close, ArrowBackIos } from "@mui/icons-material";
import { Link } from "react-router-dom";

function JobTypes() {
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const organizationId = currentUser.user.organization; // Assuming the organization ID is stored here
  const [isSalesforceSyncEnabled, setIsSalesforceSyncEnabled] = useState(false);

  const { mutate: deleteTypeMutate } = useDeleteJobType();
  const { mutate: syncJobTypesMutate, isLoading: isSyncLoading } = useSyncJobTypesFromSalesforce();

  const { isLoading, data } = useGetJobTypes(token);
  const { data: organizationDetails } = useGetOrganizationDetails(organizationId, token);

  useEffect(() => {
    if (organizationDetails?.data?.data?.salesforceSyncEnabled) {
      setIsSalesforceSyncEnabled(organizationDetails.data.data.salesforceSyncEnabled);
    }
  }, [organizationDetails]);

  const handleDelete = (id) => {
    setConfirmationPopup(true);
    setDeleteId(id);
  };

  const handleCancelDelete = () => {
    setConfirmationPopup(false);
  };

  const handleConfirmDelete = () => {
    const data = {
      id: deleteId,
      token: token,
    };
    setConfirmationPopup(false);
    deleteTypeMutate(data);
  };

  const handleSyncJobTypes = () => {
    syncJobTypesMutate({ token });
  };

  return (
    <>
      <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 relative">
        <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
          <button
            onClick={() => window.history.back()}
            className="w-full h-full rounded-full flex items-center justify-center"
          >
            <ArrowBackIos
              style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
            />
          </button>
        </div>
        <div className="flex flex-col pt-2 justify-center items-center">
          <div className="text-center font-medium text-lg">
            Project Classifications
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <table className="mt-8 w-full md:w-2/3">
              <thead>
                <tr className="bg-gray-500 text-white">
                  <th className="text-left p-2">Title</th>
                  <th className="p-2">Type</th>
                  <th className="p-2">Rate</th>
                  <th className="p-2">Retail</th>
                  <th className="p-2 w-1/6">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.data.data.map((jobtype) => (
                  <tr
                    key={jobtype._id}
                    className="bg-gray-50 border-b border-gray-100 hover:bg-gray-100"
                  >
                    <td className="p-2">{jobtype.title}</td>
                    <td className="text-center p-2">{jobtype.type}</td>
                    <td className="text-center p-2">
                      {jobtype.type === "Percentage"
                        ? `${jobtype.rate}%`
                        : `$${jobtype.rate}`}
                    </td>
                    <td className="text-center p-2">
                      {jobtype.retailRate && jobtype.type !== "Percentage"
                        ? `$${jobtype.retailRate}`
                        : ``}
                    </td>

                    <td className="text-white text-center p-2">
                      <Link to={`/editJobType/${jobtype._id}`}>
                        <span className="text-center px-2 py-1 text-xs font-medium hover:scale-105 transition-all bg-[#59c3c2] hover:bg-teal-600 rounded">
                          Edit
                        </span>
                      </Link>
                      <button
                        onClick={() => handleDelete(jobtype._id)}
                        className="ml-2 text-center  px-2 py-1 text-xs font-medium hover:scale-105 transition-all bg-gradient-to-b from-[#cc3636] to-[#941d1d] rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {isSalesforceSyncEnabled && (
            <button
              onClick={handleSyncJobTypes}
              className="text-center px-4 py-2 mt-4 text-white font-medium hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 rounded-lg"
              disabled={isSyncLoading}
            >
              {isSyncLoading ? "Syncing..." : "Sync Job Types from Salesforce"}
            </button>
          )}
          <Link to={`/addJobType`}>
            <button className="text-center px-4 py-2 mt-4 text-white font-medium hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 rounded-lg">
              + Add New
            </button>
          </Link>
        </div>
      </div>
      <div
        className={`${
          confirmationPopup ? "" : "hidden"
        } fixed inset-0 flex items-center justify-center z-50`}
      >
        <div className="bg-white rounded-lg shadow-lg px-8 py-6 relative">
          <div className="flex justify-end">
            <button className=" text-gray-500 hover:text-gray-700 absolute right-2 top-2">
              <Close
                onClick={() => setConfirmationPopup(false)}
                style={{ fontSize: "28" }}
                className="h-5 w-5"
              />
            </button>
          </div>

          <p className="text-lg mb-4 pt-4">Are you sure you want to delete?</p>
          <div className="flex justify-between">
            <button
              onClick={handleConfirmDelete}
              className="border border-[#cc3636] hover:bg-gradient-to-b hover:text-white from-[#cc3636] to-[#941d1d]  text-gray-800 font-medium py-2 px-4 rounded mr-2"
            >
              Confirm
            </button>
            <button
              onClick={handleCancelDelete}
              className="border border-gray-400 hover:border-gray-300 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobTypes;
