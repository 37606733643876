import React, { useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import Tooltip from "./Tooltip";
import { formatted } from "../utils";

function ForemanMetric(props) {
  const [isHovered, setIsHovered] = useState(false);

  const fallbackImage = "/images/avatar.jpg";
  let data = [
    { name: "Profit", value: 1 },
    { name: "Labor", value: 1 },
    { name: "NR", value: 1 },
  ];

  data = [
    { name: "Profit", value: Number(props.budget - props.labor) },
    { name: "Labor", value: Number(props.labor) },
    { name: "NR", value: Number(props.nrexpense) },
  ];

  const absolutePerformance =
    Number(props.budget) - Number(props.labor) > 0
      ? Number(props.budget) - Number(props.labor)
      : 0;
  const performancePercent =
    props.retail > 0
      ? Math.round(
          ((absolutePerformance +
            Number(props.labor) +
            (Number(props.nrexpense) || 0)) /
            Number(props.retail)) *
            1000
        ) / 10
      : "0.0";

  const colors = ["#59c3c2", "#f87171", "#64748b"];

  return (
    <div className="rounded-2xl relative shadow-xl shadow-gray-100 w-[320px] flex flex-col justify-center items-center p-4">
      <div
        className="w-16 h-16 border border-gray-200  rounded-full"
        style={{
          backgroundImage: `url("${props.img}"), url("${fallbackImage}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="font-medium">{props.name}</div>

      <PieChart className="z-10" width={300} height={200}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={80}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
      <div
        className="absolute z-20 top-[186px] font-semibold text-[#59c3c2] cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className=" text-2xl">{performancePercent}</span>
        <span className="text-lg font-bold">%</span>
        {isHovered && (
          <Tooltip text="Labor Percentage = Labor $ Used / Total Job Revenue" />
        )}
      </div>

      <div className="flex text-sm w-full justify-between items-center">
        <div className="font-semibold">Total Revenue</div>
        <div className="text-2xl font-semibold ">{formatted(props.retail)}</div>
      </div>
      <div className="flex text-sm w-full justify-between items-center">
        <div className="font-semibold">Revenue Per Hour</div>
        <div className="text-2xl font-semibold text-slate-500">
          {props.retail > 0 && props.hours > 0
            ? formatted(Number(props.retail) / Number(props.hours))
            : "$0.00"}
          /hr
        </div>
      </div>
      <div className="flex text-sm w-full justify-between items-center">
        <div className="font-semibold">Non Revenue Pay</div>
        <div className="text-2xl font-semibold text-slate-500">
          {formatted(props.nrexpense)}
        </div>
      </div>
      <div className="flex text-sm w-full justify-between items-center">
        <div className="font-semibold">Budget rate</div>
        <div className="text-2xl font-semibold text-slate-500">
          {formatted(props.budget)}
        </div>
      </div>

      <div className="flex text-sm w-full justify-between items-center">
        <div className="font-semibold">Labor used</div>
        <div className="text-2xl font-semibold  text-red-400">
          {formatted(props.labor)}
        </div>
      </div>
      <div className="flex text-sm w-full justify-between items-center">
        <div className="font-semibold">Performance Pay</div>
        <div className="text-2xl font-semibold  text-[#59c3c2]">
          {formatted(props.budget - props.labor)}
        </div>
      </div>
    </div>
  );
}

export default ForemanMetric;
