import React, { useRef, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  ArrowBackIos,
} from "@mui/icons-material";
import Loader from "../components/Loader";
import { useGetJobs } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";
import useOutsideClick from "../hooks/useOutsideClick";
import DataTable from "react-data-table-component";

import moment from "moment";
import { formatted } from "../utils";
import { useNavigate } from "react-router-dom";
// h

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
    },
  },
};

const dateSort = (rowA, rowB) => {
  const a = rowA.addedOn;
  const b = rowB.addedOn;

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export default function Jobs() {
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const userFilterRef = useRef();
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [sort, setSort] = useState("");
  const [limit, setLimit] = useState("9999");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useOutsideClick(userFilterRef, () => {
    if (openFilter) setOpenFilter(false);
  });

  const { isLoading: isJobsLoading, data: jobs } = useGetJobs(
    token,
    search,
    type,
    sort,
    limit,
    page
  );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      wrap: true,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.addedOn).format("MMM DD, YYYY"),
      sortable: true,
      sortFunction: dateSort,
    },
    {
      name: "Foreman",
      selector: (row) => row.foremanId.name,
      sortable: true,
    },
    {
      name: "Project Class",
      wrap: true,

      selector: (job) => {
        if (!job.jobType) {
          return `Full Project @ ${job.commissionRate}%`;
        } else {
          if (job.jobType === "Percentage") {
            return `${job.typeTitle} @ ${job.commissionRate}%`;
          } else {
            return `${job.typeTitle} ${job.jobType} @ $${
              job.commissionRate / 100
            }`;
          }
        }
      },
      sortable: true,
    },
    {
      name: "Total Budget",
      right: "true",
      selector: (job) => {
        return formatted(
          +((job.amount * job.commissionRate) / 100) +
            +(job.totalAdditionalBudget | 0)
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const a =
          +((rowA.amount * rowA.commissionRate) / 100) +
          +(rowA.totalAdditionalBudget | 0);
        const b =
          +((rowB.amount * rowB.commissionRate) / 100) +
          +(rowB.totalAdditionalBudget | 0);

        if (a > b) {
          return 1;
        }

        if (b > a) {
          return -1;
        }

        return 0;
      },
    },
  ];

  const handleRowClicked = (row) => {
    // Navigate to the job details page using the job ID
    navigate(`/job/${row._id}`);
  };

  return (
    <>
      <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 min-h-screen relative">
        <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
          <button
            onClick={() => window.history.back()}
            className="w-full h-full rounded-full flex items-center justify-center"
          >
            <ArrowBackIos
              style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
            />
          </button>
        </div>
        <div className="flex pt-2 justify-between gap-3 md:gap-5 lg:gap-8 xl:gap-10">
          <div className="flex px-2 py-2 w-full rounded-lg btn-shadow hover:scale-105 transition-all bg-white items-center">
            <input
              type="text"
              className="px-1 w-full bg-white outline-none"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />

            <div className="text-gray-500">
              <Close className="text-gray-400" onClick={() => setSearch("")} />
            </div>
          </div>
        </div>
        {isJobsLoading ? (
          <Loader />
        ) : (
          <>
            <div className="space-y-5 md:space-y-0 py-1 mt-8 mb-20 md:flex md:flex-wrap md:justify-between md:gap-5  rounded-lg bg-white shadow">
              <DataTable
                columns={columns}
                data={jobs.data.data}
                pagination
                highlightOnHover
                pointerOnHover
                onRowClicked={handleRowClicked}
                customStyles={customStyles}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
