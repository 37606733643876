// Custom Toast component using Tailwind CSS
const showToast = (message, status) => {
  const toast = document.createElement("div");
  toast.innerHTML = message;
  toast.className = `fixed bottom-0 right-0 mb-8 mr-8 px-8 py-4 rounded-lg shadow-lg ${
    status === "success" ? "bg-green-500" : "bg-red-500"
  } text-white z-50 transition-all duration-300`;
  document.body.appendChild(toast);

  setTimeout(() => {
    toast.style.opacity = 0;
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 300);
  }, 3000);
};

export default showToast;
