import Home from "./pages/Home";
import "./App.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Users from "./pages/Users";
import Profile from "./pages/Profile";
import AddWorkers from "./pages/AddWorkers";
import ChangePassword from "./pages/ChangePassword";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import { useSelector } from "react-redux";
import EditUser from "./pages/EditUser";
import CreateUser from "./pages/CreateUser";
import UserDetails from "./pages/UserDetails";
import EditProfile from "./pages/EditProfile";
import { ScrollToTop } from "./hooks/ScrollToTop";
import DownloadCSV from "./pages/DownloadCSV";
import History from "./pages/History";
import JobTypes from "./pages/JobTypes";
import AddJobType from "./pages/AddJobType";
import EditJobType from "./pages/EditJobType";
import Metrics from "./pages/Metrics";
import Settings from "./pages/Settings";
import Jobs from "./pages/Jobs";
import Job from "./pages/JobDetail";

const queryClient = new QueryClient();

function App() {
  const { currentUser } = useSelector((state) => state.userSlice);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        {currentUser?.user.role === "admin" ? (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/users" element={<Users />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/job/:jobId" element={<Job />} />
              <Route path="/UserDetails/:userId" element={<UserDetails />} />
              <Route path="/editUser/:userId" element={<EditUser />} />
              <Route path="/createUser" element={<CreateUser />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/editProfile" element={<EditProfile />} />
              <Route path="/addWorkers/:userId" element={<AddWorkers />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/downloadCSV" element={<DownloadCSV />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/history/:userId" element={<Metrics />} />
              <Route path="/jobtypes" element={<JobTypes />} />
              <Route path="/addJobType" element={<AddJobType />} />
              <Route path="/editJobType/:id" element={<EditJobType />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/generatePassword" element={<ForgetPassword />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
      <ReactQueryDevtools intialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
