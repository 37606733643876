import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import Tooltip from "./Tooltip";
import { formatted } from "../utils";
import { useState } from "react";

function SalesMetric(props) {
  const [isHovered, setIsHovered] = useState(false);
  const fallbackImage = "/images/avatar.jpg";
  let data = [
    { name: "Amount", value: 1 },
    { name: "Bonus", value: 1 },
  ];

  data = [
    { name: "Amount", value: Number(props.amount) },
    { name: "Bonus", value: Number(props.bonus) },
  ];

  const performancePercent =
    props.amount > 0 && props.bonus > 0
      ? Math.round((Number(props.bonus) / Number(props.amount)) * 1000) / 10
      : "0.0";

  const colors = ["#59c3c2", "#f87171"];

  return (
    <div className="rounded-2xl relative shadow-xl shadow-gray-100 w-[320px] flex flex-col justify-center items-center p-4">
      <div
        className="w-16 h-16 border border-gray-200  rounded-full"
        style={{
          backgroundImage: `url("${props.img}"), url("${fallbackImage}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="font-medium">{props.name}</div>

      <PieChart className="z-10" width={300} height={200}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={80}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
      <div
        className="absolute z-20 top-[186px] font-semibold text-[#59c3c2] cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className=" text-2xl">{performancePercent}</span>
        <span className="text-lg font-bold">%</span>
        {isHovered && (
          <Tooltip text="Sales Percentage = Total Bonus / Total Sales" />
        )}
      </div>

      <div className="text-center font-semibold mb-2">No. of Jobs</div>
      <div className="text-center text-4xl font-semibold mb-4">
        {props.jobs}
      </div>
      <div className="text-center font-semibold mb-2">Bonus</div>
      <div className="text-center text-4xl font-semibold mb-4 text-red-400">
        {formatted(props.bonus)}
      </div>
      <div className="text-center font-semibold mb-2">Sales</div>
      <div className="text-center text-4xl font-semibold mb-4 text-[#59c3c2]">
        {formatted(props.amount)}
      </div>
    </div>
  );
}

export default SalesMetric;
