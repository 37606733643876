import React, { useState } from "react";
import { useSelector } from "react-redux";
import { downloadCSV } from "../apiCalls/userApiCalls";
import moment from "moment";
import WeekSelector from "../components/WeekSelector";

export default function DownloadCSV() {
  const [week, setWeek] = useState(moment().isoWeek());
  const [year, setYear] = useState(moment().isoWeekYear());

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;

  function handleWeekChange({ week, year }) {
    setWeek(week);
    setYear(year);
  }

  return (
    <>
      <div className="flex flex-col justify-center mt-32 px-24">
        <WeekSelector week={week} year={year} onWeekChange={handleWeekChange} />
        <div className="text-center mt-4">
          <button
            className="px-4 py-1 text-sm font-medium bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-md border-none text-white "
            onClick={(e) => downloadCSV(week, year, token)}
          >
            Download CSV
          </button>
        </div>
      </div>
    </>
  );
}
