import React, { useState, useEffect } from "react";
import moment from "moment";

const WeekSelector = ({
  week: initialWeek = moment().isoWeek(),
  year: initialYear = moment().isoWeekYear(),
  onWeekChange,
}) => {
  const [week, setWeek] = useState(initialWeek);
  const [year, setYear] = useState(initialYear);

  useEffect(() => {
    onWeekChange({ week, year });
  }, [week, year, onWeekChange]);

  const weeksInYear = moment().isoWeeksInYear();
  const years = [...Array(41).keys()].map((i) => i + 2000);

  const incrementWeek = () => {
    if (week < weeksInYear) {
      setWeek(week + 1);
    } else {
      setWeek(1);
      setYear(year + 1);
    }
  };

  const decrementWeek = () => {
    if (week > 1) {
      setWeek(week - 1);
    } else {
      setWeek(weeksInYear);
      setYear(year - 1);
    }
  };

  return (
    <div className="flex justify-between items-center w-full">
      <button
        className="font-bold px-2 pt-1 pb-1.5 rounded-lg text-gray-300 border border-gray-300 hover:bg-gray-100"
        onClick={decrementWeek}
      >
        &lt;
      </button>
      <div className="flex gap-2">
        <select
          className="rounded-md p-1 border border-gray-300 bg-white"
          value={year}
          onChange={(e) => setYear(parseInt(e.target.value))}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          className="rounded-md p-1 border border-gray-300 bg-white"
          value={week}
          onChange={(e) => setWeek(parseInt(e.target.value))}
        >
          {[...Array(weeksInYear).keys()].map((i) => {
            const startDate = moment()
              .isoWeekYear(year)
              .isoWeek(i + 1)
              .startOf("isoWeek")
              .format("DD MMM");
            const endDate = moment()
              .isoWeekYear(year)
              .isoWeek(i + 1)
              .endOf("isoWeek")
              .format("DD MMM");
            return (
              <option key={i + 1} value={i + 1}>
                {startDate} - {endDate}
              </option>
            );
          })}
        </select>
      </div>
      <button
        className="font-bold px-2 pt-1 pb-1.5 rounded-lg text-gray-300 border border-gray-300 hover:bg-gray-100"
        onClick={incrementWeek}
      >
        &gt;
      </button>
    </div>
  );
};

export default WeekSelector;
