import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { persistor } from "../redux/store";
import { loginSuccess, logoutSuccess } from "../redux/reducers/userReducers ";
import { useDispatch } from "react-redux";
import showToast from "../components/Toast";
import { API_BASE_URL } from "../config";
import fileDownload from "js-file-download";
import moment from "moment";

// admin to login

export const login = async (userData) => {
  return axios.post(`${API_BASE_URL}/auth/login`, userData);
};

export const useLogin = () => {
  const dispatch = useDispatch();
  return useMutation(login, {
    onSuccess: (data) => {
      dispatch(loginSuccess(data.data));
      if (data.data.user.role === "admin") {
        showToast("User logged in Successfully!", "success");
      } else {
        showToast("User is Unauthorized!", "error");
      }
    },
  });
};

// admin to logout

export const logout = async () => {
  return axios.get(`${API_BASE_URL}/auth/logout`);
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation(logout, {
    onSuccess: (data) => {
      dispatch(logoutSuccess());
      persistor.purge();
      navigate("/");
      showToast("User logout Successfully!", "success");
    },
  });
};

// get all users

const getUsers = async (
  token,
  search = "",
  type = "",
  sort = "",
  limit = "",
  page = 1
) => {
  return await axios.get(
    `${API_BASE_URL}/admin/users?search=${search}&type=${type}&sort=${sort}&limit=${limit}&page=${page}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetUsers = (token, search, type, sort, limit, page) => {
  return useQuery(["users", token, search, type, sort, limit, page], () =>
    getUsers(token, search, type, sort, limit, page)
  );
};

// get all jobs

const getJobs = async (
  token,
  search = "",
  type = "",
  sort = "",
  limit = "",
  page = 1
) => {
  return await axios.get(
    `${API_BASE_URL}/admin/jobs?search=${search}&type=${type}&sort=${sort}&limit=${limit}&page=${page}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetJobs = (token, search, type, sort, limit, page) => {
  return useQuery(["jobs", token, search, type, sort, limit, page], () =>
    getJobs(token, search, type, sort, limit, page)
  );
};

// get user details

const getUserDetails = async (userId, token) => {
  return axios.get(`${API_BASE_URL}/admin/users/${userId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetUserDetails = (userId, token) => {
  return useQuery(["user", userId, token], () => getUserDetails(userId, token));
};

// delete user

export const deleteUser = async (userData) => {
  return axios.delete(`${API_BASE_URL}/admin/users/${userData.userId}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("users");
      showToast("User deleted Sucessfully!", "success");
    },
  });
};

// update user

export const updateUser = async (userData) => {
  return axios.put(`${API_BASE_URL}/admin/users/${userData.userId}`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.resetQueries("user");
      showToast("User Updated Successfully!", "success");
    },
  });
};

// create user

export const createUser = async (userData) => {
  return axios.post(`${API_BASE_URL}/admin/users`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useCreateUser = () => {
  const navigate = useNavigate();
  return useMutation(createUser, {
    onSuccess: (data) => {
      navigate("/users");
      showToast("User created Successfully!", "success");
    },
  });
};

// assign workers to foreman

export const addWorkers = async (userData) => {
  return axios.post(`${API_BASE_URL}/admin/assign-workers`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useAddWorkers = () => {
  const queryClient = useQueryClient();
  return useMutation(addWorkers, {
    onSuccess: (data) => {
      queryClient.resetQueries("user");
      showToast("Worker Added Successfully!", "success");
    },
  });
};

// admin to change password

export const changePassword = async (userData) => {
  return axios.post(`${API_BASE_URL}auth/change-password`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useChangePassword = () => {
  return useMutation(changePassword, {
    onSuccess: (data) => {
      showToast("Password changed Successfully!", "success");
    },
  });
};

// remove workers from foreman

export const removeWorkers = async (userData) => {
  return axios.put(`${API_BASE_URL}/admin/remove-worker`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useRemoveWorkers = () => {
  const queryClient = useQueryClient();
  return useMutation(removeWorkers, {
    onSuccess: (data) => {
      queryClient.resetQueries("user");
      showToast("Worker removed Successfully!", "success");
    },
  });
};

// update user image

export const updateUserImage = async (userData) => {
  return axios.put(
    `${API_BASE_URL}/admin/update-user-image/${userData.userId}`,
    userData,
    {
      headers: {
        authorization: "Bearer " + userData.token,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const useUpdateUserImage = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUserImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("user");
      showToast("image uploaded Successfully!", "success");
    },
  });
};

// Send Invite

export const sendInvite = async (userData) => {
  return axios.post(`${API_BASE_URL}/admin/send-invite`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useSendInvite = () => {
  return useMutation(sendInvite, {
    onSuccess: (data) => {
      showToast("Invite Sent Successfully!", "success");
    },
  });
};

// Generate Password

export const generatePassword = async (userData) => {
  return axios.put(`${API_BASE_URL}/auth/resetPassword`, userData);
};

export const useGeneratePassword = () => {
  return useMutation(generatePassword, {
    onSuccess: (data) => {
      showToast("Password Generated Successfully!", "success");
    },
  });
};

// Generate Otp

export const generateOtp = async (userData) => {
  return axios.post(`${API_BASE_URL}/auth/generateOtp`, userData);
};

export const useGenerateOtp = () => {
  return useMutation(generateOtp, {
    onSuccess: (data) => {
      showToast("OTP Generated Successfully!", "success");
    },
  });
};

// get dashboard metric

const getMetrics = async (
  token,
  type = "foreman",
  startDate = "2023-01-01",
  endDate = "2024-01-01"
) => {
  return await axios.get(
    `${API_BASE_URL}/admin/get-dashboard-metrics?type=${type}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetMetrics = (token, type, startDate, endDate) => {
  return useQuery(["metric", token, type, startDate, endDate], () =>
    getMetrics(token, type, startDate, endDate)
  );
};

// get history

const getHistory = async (token, userId, startDate, endDate) => {
  return await axios.get(
    `${API_BASE_URL}/admin/get-performance-history?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetHistory = (token, userId, startDate, endDate) => {
  return useQuery(["history", token, userId, startDate, endDate], () =>
    getHistory(token, userId, startDate, endDate)
  );
};

// download CSV
export const downloadCSV = async (w, y, token) => {
  try {
    const data = {
      week: w,
      year: y,
    };
    const response = await axios.post(
      `${API_BASE_URL}/admin/download-csv`,
      data,
      {
        headers: { authorization: "Bearer " + token },
        responseType: "blob", // Set responseType to 'blob'
      }
    );

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a link element, use it to download the blob, and then remove it
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `payroll-${w}-${y}.csv`; // Use the same name as the server or a name you prefer
    document.body.appendChild(link);
    link.click(); // Simulate a click on the link to download the file
    document.body.removeChild(link); // Remove the link when done
  } catch (error) {
    // Handle error here
    console.log(error);
  }
};

//------------------------------------- Add job type

export const addJobType = async (userData) => {
  return axios.post(`${API_BASE_URL}/admin/job-types`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useAddJobType = () => {
  const navigate = useNavigate();
  return useMutation(addJobType, {
    onSuccess: (data) => {
      navigate("/jobtypes");
      showToast("Classification created Successfully!", "success");
    },
  });
};
//------------------------------------- Add job type

export const editJobType = async (userData) => {
  return axios.put(`${API_BASE_URL}/admin/job-types/${userData.id}`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useEditJobType = () => {
  return useMutation(editJobType, {
    onSuccess: (data) => {
      showToast("Classification updated Successfully!", "success");
    },
  });
};

//---------------------------------- get all job types

const getJobTypes = async (token) => {
  return await axios.get(`${API_BASE_URL}/admin/job-types`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetJobTypes = (token) => {
  return useQuery(["jobtypes", token], () => getJobTypes(token));
};

//---------------------------------- get Job type

const getJobType = async (id, token) => {
  return await axios.get(`${API_BASE_URL}/admin/job-types/${id}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetJobType = (id, token) => {
  return useQuery(["jobtype", token], () => getJobType(id, token));
};

// --delete job type
export const deleteJobType = async (userData) => {
  return axios.delete(`${API_BASE_URL}/admin/job-types/${userData.id}`, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useDeleteJobType = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteJobType, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("jobtypes");
      showToast("Classification deleted Sucessfully!", "success");
    },
  });
};

// get job type details

const getJobTypeDetails = async (id, token) => {
  return axios.get(`${API_BASE_URL}/admin/job-type/${id}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetJobTypeDetails = (id, token) => {
  return useQuery(["jobtype", id, token], () => getJobTypeDetails(id, token));
};

// update job type

export const updateJobType = async (userData) => {
  return axios.put(`${API_BASE_URL}/admin/users/${userData.userId}`, userData, {
    headers: {
      authorization: "Bearer " + userData.token,
    },
  });
};

export const useUpdateJobType = () => {
  const queryClient = useQueryClient();
  return useMutation(updateJobType, {
    onSuccess: (data) => {
      queryClient.resetQueries("jobtype");
      showToast("Classification Updated Successfully!", "success");
    },
  });
};

// download PDF
export const downloadPDF = async (w, y, token, note, foremanId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/admin/pdf?week=${w}&year=${y}&note=${note}&foremanId=${foremanId}`,
      {
        headers: { authorization: "Bearer " + token },
        responseType: "blob", // Important
      }
    );

    const filename = `${moment().format("YYYYMMDDHHmmssSSS")}.pdf`;
    fileDownload(response.data, filename);
  } catch (error) {
    // Handle error here
    console.log(error);
  }
};

// get Metrics
const getForemanMetrics = async (s, e, token, f) => {
  return axios.get(
    `${API_BASE_URL}/admin/foremanMetrics?startDate=${s}&endDate=${e}&foremanId=${f}`,
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export const useGetForemanMetrics = (s, e, token, f) => {
  return useQuery(["fmetrics", s, e, token, f], () =>
    getForemanMetrics(s, e, token, f)
  );
};

// get Job details

const getJobDetails = async (jobId, token) => {
  return axios.get(`${API_BASE_URL}/admin/job/${jobId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetJobDetails = (jobId, token) => {
  return useQuery(["job", jobId, token], () => getJobDetails(jobId, token));
};

// Get all organizations

const getOrganizations = async (token) => {
  return await axios.get(`${API_BASE_URL}/admin/organizations`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetOrganizations = (token) => {
  return useQuery(["organizations", token], () => getOrganizations(token));
};

// Get organization details

const getOrganizationDetails = async (orgId, token) => {
  return axios.get(`${API_BASE_URL}/admin/organizations/${orgId}`, {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export const useGetOrganizationDetails = (orgId, token) => {
  return useQuery(["organization", orgId, token], () =>
    getOrganizationDetails(orgId, token)
  );
};

// Create organization

export const createOrganization = async (orgData) => {
  return axios.post(`${API_BASE_URL}/admin/organizations`, orgData, {
    headers: {
      authorization: "Bearer " + orgData.token,
    },
  });
};

export const useCreateOrganization = () => {
  const navigate = useNavigate();
  return useMutation(createOrganization, {
    onSuccess: (data) => {
      navigate(`/organizationDetails/${data.data._id}`);
      showToast("Organization created Successfully!", "success");
    },
  });
};

// Update organization

export const updateOrganization = async (orgData) => {
  return axios.put(
    `${API_BASE_URL}/admin/organizations/${orgData.id}`,
    orgData,
    {
      headers: {
        authorization: "Bearer " + orgData.token,
      },
    }
  );
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(updateOrganization, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("organization");
      showToast("Organization updated Successfully!", "success");
      navigate(`/organizationDetails/${data.data._id}`);
    },
  });
};

// Sync job types from Salesforce
export const syncJobTypesFromSalesforce = async (data) => {
  return axios.post(`${API_BASE_URL}/admin/sync/jobtypes`, {}, {
    headers: {
      authorization: "Bearer " + data.token,
    },
  });
};

export const useSyncJobTypesFromSalesforce = () => {
  const queryClient = useQueryClient();
  return useMutation(syncJobTypesFromSalesforce, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("jobtypes");
      showToast("Job Types synced successfully!", "success");
    },
    onError: (error) => {
      showToast("Failed to sync Job Types!", "error");
    }
  });
};